import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import featuredCatalogStyles from "./featuredCatalog.module.scss"

import ProductCard from "../productCard/productCard"

function FeaturedCatalog({ collection }) {
  const featuredCatalogQuery = useStaticQuery(graphql`
    query {
      women: shopifyCollection(title: { eq: "Homepage-Featured" }) {
        products {
          id
          handle
          title
          images {
            localFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          variants {
            selectedOptions {
              name
              value
            }
            shopifyId
            compareAtPrice
          }
          priceRange {
            minVariantPrice {
              amount
            }
          }
          options {
            name
            values
          }
        }
      }
      plus: shopifyCollection(title: { eq: "Homepage-Featured-Plus" }) {
        products {
          id
          handle
          title
          images {
            localFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          variants {
            selectedOptions {
              name
              value
            }
            shopifyId
            compareAtPrice
          }
          priceRange {
            minVariantPrice {
              amount
            }
          }
          options {
            name
            values
          }
        }
      }
      maternity: shopifyCollection(
        title: { eq: "Homepage-Featured-Maternity" }
      ) {
        products {
          id
          handle
          title
          images {
            localFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          variants {
            selectedOptions {
              name
              value
            }
            shopifyId
            compareAtPrice
          }
          priceRange {
            minVariantPrice {
              amount
            }
          }
          options {
            name
            values
          }
        }
      }
    }
  `)

  const featuredCatalog =
    collection === "Women"
      ? featuredCatalogQuery.women
      : collection === "Maternity"
      ? featuredCatalogQuery.maternity
      : featuredCatalogQuery.plus

  const products = () => {
    return featuredCatalog.products.map((product, i) => {
      let color = product.options[1].values[0]
      let sizeID = product.variants
        .filter(variant => variant.selectedOptions[1].value === color)
        .map(variant => [variant.selectedOptions[0].value, variant.shopifyId])

      return (
        <React.Fragment key={`${product.id}-${i}-featured-fragment`}>
          {collection === "Women" ? (
            <Link
              className={featuredCatalogStyles.cardContainer}
              key={`${product.id}-${i}-featured-women`}
              to={`/women/${product.handle}-${product.options[1].values[0]}`
                .replace(/ /g, "-")
                .toLowerCase()}
            >
              <ProductCard
                imgFront={product.images[0].localFile.childImageSharp.fluid}
                imgBack={product.images[1].localFile.childImageSharp.fluid}
                title={`${product.title} - ${product.options[1].values[0]}`}
                price={product.priceRange.minVariantPrice.amount}
                compareAt={product.variants[0].compareAtPrice}
                sizes={sizeID}
              />
            </Link>
          ) : collection === "Maternity" ? (
            <Link
              className={featuredCatalogStyles.cardContainer}
              key={`${product.id}-${i}-featured-maternity`}
              to={`/maternity/${product.handle}-${product.options[1].values[0]}`
                .replace(/ /g, "-")
                .toLowerCase()}
            >
              <ProductCard
                imgFront={product.images[0].localFile.childImageSharp.fluid}
                imgBack={product.images[1].localFile.childImageSharp.fluid}
                title={`${product.title} - ${product.options[1].values[0]}`}
                price={product.priceRange.minVariantPrice.amount}
                compareAt={product.variants[0].compareAtPrice}
                sizes={sizeID}
              />
            </Link>
          ) : (
            <Link
              className={featuredCatalogStyles.cardContainer}
              key={`${product.id}-${i}-featured-plus`}
              to={`/women/${product.handle}-${product.options[1].values[0]}`
                .replace(/ /g, "-")
                .toLowerCase()}
            >
              <ProductCard
                imgFront={product.images[0].localFile.childImageSharp.fluid}
                imgBack={product.images[1].localFile.childImageSharp.fluid}
                title={`${product.title} - ${product.options[1].values[0]}`}
                price={product.priceRange.minVariantPrice.amount}
                compareAt={product.variants[0].compareAtPrice}
                sizes={sizeID}
              />
            </Link>
          )}
        </React.Fragment>
      )
    })
  }

  return (
    <div className={featuredCatalogStyles.cardGridContainer}>
      <h2
        className={featuredCatalogStyles.heading}
      >{`${collection} Favorites`}</h2>
      <div className={featuredCatalogStyles.headingLine} />
      <div className={featuredCatalogStyles.cardGrid}>{products()}</div>
    </div>
  )
}

export default FeaturedCatalog
